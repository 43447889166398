import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './components/Layout/Layout';
import Home from './components/Home/Home';
import Projects from './components/Projects/Projects';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import { ReactComponent as MySvgComponent } from './components/Home/pre.svg';
import Footer from './components/Footer/Footer';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay to demonstrate loading
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the delay as needed

    // Clean up the timer
    return () => clearTimeout(timer);
  }, []);

return (
  <div className='app-container'>
    <div className='content'>
      {loading ? (
      <div className="loading-container">
        <MySvgComponent className="loading-svg" />
      </div>
      ) : (
    <div className='router-container'>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="Projects" element={<Projects />} />
            <Route path="About" element={<About />} />
            <Route path="Contact" element={<Contact />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
    )}
  </div>
    <Footer />
  </div>
);
};
  

export default App;

