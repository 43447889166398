import React from 'react';
import Project from './Project';
import quotegenerator from './Projects.assets/quotegenerator.png';
import robotar from './Projects.assets/robotar.png';
import joketeller from './Projects.assets/joketeller.png';
import pokeapi from './Projects.assets/pokeapi.png';
import website from './Projects.assets/website.png'
import tasktracker from './Projects.assets/tasktracker.png';
import './Projects.css';

const Projects = () => {
  const projects = [
    { header: 'Pokémon-Gallery', image: pokeapi, url: 'https://oedvinsson.github.io/Pokemon-Gallery/', github: "https://github.com/oEdvinsson/Pokemon-Gallery", text: "This project is a nice showcase of the original 151 Pokémon, all thanks to the PokéAPI. The gallery has a neat layout with four columns and works well on any device. Each Pokémon card shows important stats, so you can learn more about them. Hover over a card, and it flips around to show the Pokémon's backside, making it even more fun to explore. It's a great example of my front-end development and API integration skills." },
    { header: 'Joke-Teller', image: joketeller, url: 'https://oedvinsson.github.io/Joke-Teller/', github: "https://github.com/oEdvinsson/Joke-Teller", text: "This web app is a fun interactive experience where a cute robot tells you programmer jokes. Just click a button, and the robot will share a joke with you. I made this by using two APIs: one for fetching jokes and another for turning them into spoken words. It's a cool showcase of how APIs, animation, and interactivity can work together. So, come have a laugh and explore this fun project!" },
    { header: 'Quote-Generator', image: quotegenerator, url: 'https://oedvinsson.github.io/Quote-Generator/', github: "https://github.com/oEdvinsson/Quote-Generator", text: "This is a cool web app that fetches inspiring quotes from an extensive database. Just click a button to get a new quote and feel motivated! I made it using JavaScript, HTML, and CSS, so it's easy to use and looks good. Plus, you can share your favorite quotes on Twitter with a single click. Whether you need some wisdom or just want to feel inspired, this project is perfect for you. It shows off my skills in web development and how I can make things work smoothly." },
    { header: 'Robofriends', image: robotar, url: 'https://oedvinsson.github.io/robotar/', github: "https://github.com/oEdvinsson/robotar/tree/master", text: "This project was my debut React project and it's a gallery featuring 10 robot cards, each displaying a picture, name, and email. You can easily filter the robots by typing in the search field. I utilized both class and function components and used Tachyons for the styling. Additionally, Robofriends fetches robot data via an API, further improving my skills and comprehension in React development." },
    { header: 'Website', image: website, url: 'https://oedvinsson.github.io/Mywebsite/', github: "https://github.com/oEdvinsson/robotar/tree/master", text: "Welcome to my first website! Built using vanilla HTML and CSS and with a clean and straightforward card layout design, this website introduces me as an aspiring web developer. It features an about me section, a small project showcase, my professional and educational background, and lastly, a contact form. Though my skills have evolved since its creation, its legacy continues to inspire my growth in the field of web development." },
    { header: 'Task-Tracker', image: tasktracker, url: 'https://oedvinsson.github.io/Task-Manager/', github: "https://github.com/oEdvinsson/Task-Manager", text: "This is a web app designed for effortless task management in a simple and intuitive interface. With this tool, adding tasks to your list is easy. Simply provide a task name, time, and optionally set a reminder with just a click. Removing tasks is equally straightforward – just click the cross icon associated with each task. Furthermore, you can toggle the display of the add task section with the click of a button. This was a fun project that put my skills in DOM manipulation to the test!" }
  ];
  
    return (
      <div>
        <div className='centered-header'>
          <h1 className='projects-heading'>Recent Top <span className='purple'>Works</span></h1>
          <p>Here is a showcase of a few of my projects</p>
        </div>
        <div className='projects-grid'>
          {projects.map((project, index) => (
            <div key={index} className="project-container">
              <Project header={project.header} image={project.image}
              url={project.url} text={project.text} github={project.github} />
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  export default Projects;


