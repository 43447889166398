import React, { useState, useRef } from 'react';
import emailjs from 'email-js'; // Import emailjs from emailjs-com package

const Form = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const form = useRef(); // Move useRef outside of the component function

  const handleChange = (e) => {
    // Update formData state when input values change
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const sendEmail = (e) => {
    e.preventDefault(); // Prevent page reload

    // Use emailjs.sendForm to send email
    emailjs.sendForm('service_aemngnk', '__ejs-test-mail-service__', form.current, 'gGH7uZrSZC55E6LeC')
      .then((result) => {
        console.log('Email sent successfully:', result.text);
        // Show success message to the user
      }, (error) => {
        console.error('Failed to send email:', error);
        // Show error message to the user
      });

    // Clear form after submission
    setFormData({
      name: '',
      email: '',
      message: ''
    });
  };

  return (
    <div>
      <form className='form' ref={form} onSubmit={sendEmail}>
        <label htmlFor="name"></label><br />
        <input
          type="text"
          id="name"
          name="name"
          placeholder="Name"
          value={formData.name}
          onChange={handleChange}
          required
        /><br />

        <label htmlFor="email"></label><br />
        <input
          type="email"
          id="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          required
        /><br />

        <label htmlFor="message"></label><br />
        <textarea
          id="message"
          name="message"
          placeholder="Message"
          value={formData.message}
          onChange={handleChange}
          rows="4"
          cols="50"
          required
        ></textarea><br />

        <input type="submit" id="formBtn" value="Send" />
      </form>
    </div>
  );
};

export default Form;

