import { Outlet, Link } from "react-router-dom";
import './Layout.css';
import { CgFileDocument } from "react-icons/cg";
import {
    AiOutlineHome,
    AiOutlineFundProjectionScreen,
    AiOutlineContacts
  } from "react-icons/ai";

const Layout = () => {
  return (
    <div>
        <nav className="navBar">
            <ul className="nav-list">
                <li>
                    <Link to="/"><AiOutlineHome className="symbol"/>Home</Link>
                </li>
                <li>
                    <Link to="/Projects"><AiOutlineFundProjectionScreen className="symbol"/>Projects</Link>
                </li>
                <li>
                    <Link to="/About"><CgFileDocument className="symbol"/>About</Link>
                </li>
                <li>
                    <Link to="/Contact"><AiOutlineContacts className="symbol"/>Contact</Link>
                </li>
            </ul>
        </nav>
        <div>
            <Outlet />
        </div>
    </div>
  );
};

export default Layout;

