import React from 'react';
import aboutImage from './aboutImage.svg'
import './About.css';


const About = () => {
  return (
    <div className="about">
      <div>
        <h1 className='about-heading'>LET ME <span className='purple'>INTRODUCE</span> MYSELF</h1><br/>
        <p>
          I'm <span className='purple'>Oscar Edvinsson</span>, a self-taught junior Front-End Developer from <span className='purple'>Stockholm, Sweden.</span><br/><br/>
          I kicked off my journey into HTML, CSS, and JavaScript with a passion, and React? Absolutely, count me in!<br/><br/>
          As a <span className='purple'>Front-End</span> developer, I enjoy tackling new challenges and continuously expanding my skillset.<br/><br/>
          Now, I'm actively seeking opportunities in the <span className='purple'>web development</span> field to apply my skills and passion.<br/><br/>
          Even though I don't have a degree, I've got plenty of <span className='purple'>hands-on experience</span> and a strong drive to tackle any challenge.<br/><br/>
          Creating <span className='purple'>cool-looking</span> websites is what I do best, and I always keep up with the latest tech trends.<br/><br/>
          AI? Yep, I'm interested in exploring that too.<br/><br/>
          Let's collaborate on something <span className='purple'>awesome!</span>
        </p>
      </div>
      <div className='about-image-container'>
        <img src={aboutImage} className='aboutImage' alt="" />
      </div>
    </div>
  );
};

export default About;