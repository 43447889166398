import React from 'react'
import {  FaLinkedinIn, FaInstagram } from "react-icons/fa";
import { AiFillGithub, AiOutlineTwitter } from "react-icons/ai";
import './Findme.css'

const Findme = () => {
  return (
    <div className='findme-container'>
        <h3>FIND ME ON</h3>
        <p>Please don't hesitate to reach out to me and <span className='purple'>connect</span></p>
        <div className='social-icons-box'>
            <ul className='contact-social-icons'>
                <li><a href="https://www.linkedin.com/in/oscar-edvinsson-62b46a101/" target="_blank"><FaLinkedinIn /></a></li>
                <li><a href="https://github.com/oEdvinsson" target="_blank"><AiFillGithub /></a></li>
                <li><a href="https://twitter.com/OscarEdvinsson" target="_blank"><AiOutlineTwitter /></a></li>
                <li><a href="https://www.linkedin.com/in/oscar-edvinsson-62b46a101/" target="_blank"><FaInstagram /></a></li>
            </ul>
        </div>
    </div>
  )
}

export default Findme