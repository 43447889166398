import React from 'react';
import { BsGithub } from "react-icons/bs";
import './Project.css';

const Project = ({ header, image, url, text, github }) => {
  return (
    <div className='project'>
      <a href={url} target="_blank" rel="noopener noreferrer">
        <img className='project-image' src={image} alt={header} />
      </a>
      <h2 className='project-header'>{header}</h2>
      <p className='project-text'>{text}</p>
      <a href={github} target="_blank"><button><BsGithub className="github" />GitHub</button></a>
    </div>
  );
};

export default Project;

