import React from "react";
import Typewriter from "typewriter-effect";

const Type = () => {
    return (
      <div className="purple typewriter">
        <Typewriter
          options={{
            strings: [
              "Skilled Frontend Engineer",
              "Innovative UI/UX Designer",
              "Keen learner, always exploring new technologies",
              "Effective communicator and team player",
              "Passionate about creating delightful user experiences",
              "Dedicated to writing clean, maintainable code"
            ],
            autoStart: true,
            loop: true,
            deleteSpeed: 50,
          }}
        />
      </div>
      )
}

export default Type;