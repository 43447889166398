import React from 'react'
import { BsGithub, BsTwitterX, BsLink, BsInstagram } from "react-icons/bs"
import './Footer.css'

const Footer = () => {
  return (
    <div className='footer'>
        <div><p>Dedicated to creating impactful solutions!</p></div>
        <div><p>All rights reserved &copy; Oscar Edvinsson</p></div>
        <div>
          <a href="https://github.com/oEdvinsson" target="_blank"><BsGithub className="footer-icons" /></a>
          <a href="https://twitter.com/OscarEdvinsson" target="_blank"><BsTwitterX className="footer-icons" /></a>
          <a href="https://www.linkedin.com/in/oscar-edvinsson-62b46a101/" target="_blank"><BsLink className="footer-icons" /></a>
          <a href="https://www.instagram.com/oscaredvinsson/" target="_blank"><BsInstagram className="footer-icons" /></a>
        </div>
    </div>
  )
}

export default Footer