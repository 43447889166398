import React from 'react'
import Form from './Form'
import Findme from './Findme'
import './Contact.css'
import oscarImage from './oscarImage.jpg';

const Contact = () => {
  return (
    <div className='contact-page'>
      <div className='contact-container'>
        <div className='contact-headings-container'>
          <h2>Get in Touch</h2>
          <h2><span className='purple'>Contact Me!</span></h2>
          <img src={oscarImage} className='oscarImage' alt='' />
        </div>
        <div>
          <Form />
        </div>
      </div>
      <div>
        <Findme />
      </div>
    </div>
  )
}

export default Contact