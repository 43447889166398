import React from 'react';
import homeImage from './about.png';
import Typewriter from './Typewriter';
import './Home.css';

const Home = () => {
  return (
    <div className='home'>
      <div className='home-text'>
        <h1>Hi There!</h1><br />
        <h1>I'M <span className='purple'>Oscar Edvinsson</span></h1>
        <Typewriter />
      </div>
      <div className='homeImage-container'>
      </div>
        <img src={homeImage} className='homeImage' alt='about' />
    </div>
  );
};

export default Home;
